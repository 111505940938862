<template>
  <div class="h-quick-shop">
    <div v-if="isMobile" class="mobileDialogTitle">
      <h5 class="text-h5">Shop faster</h5>
      <q-icon class="close cursor-pointer" name="close" v-close-popup />
    </div>
    <h4 v-else>
      <q-icon class="close cursor-pointer" name="close" v-close-popup /> Shop
      faster - type or paste your <br />
      shopping list below
    </h4>
    <div class="row">
      <div class="col-12 q-mb-md">
        <q-input
          flat
          @keyup.enter.stop
          v-model.trim="textarea"
          borderless
          type="textarea"
          rows="4"
          placeholder="e.g. Butane Pure, JUUL, Bouldar Tobacco"
        />
      </div>

      <div class="col-6 q-pr-sm">
        <q-btn
          unelevated
          no-caps
          class="full-width"
          color="grey"
          @click="clear"
        >
          Clear
        </q-btn>
      </div>
      <div class="col-6 q-pl-sm">
        <q-btn
          :disabled="!textarea"
          unelevated
          no-caps
          class="full-width"
          color="primaryOnBody"
          @click="searhNow"
        >
          Search Now
        </q-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      textarea: '',
    }
  },
  methods: {
    searhNow() {
      let splittedTexts = this.textarea.split(/[\n,]/)

      splittedTexts = splittedTexts.reduce(function(filtered, splittedText) {
        if (splittedText.trim()) {
          filtered.push(splittedText.trim())
        }
        return filtered
      }, [])
      if (Array.isArray(splittedTexts) && splittedTexts.toString() == '') {
        this.showError('Search input is not valid.')
        return
      }

      // splittedTextsString = splittedTexts.join(',')

      // if (
      //   splittedTexts.length > 0 &&
      //   !(
      //     this.$route.name == 'Search' &&
      //     this.$route.query.q == splittedTextsString
      //   )
      // ) {
      let queryParam = Array.isArray(this.$route.query.q)
        ? this.$route.query.q.toString()
        : this.$route.query.q

      let compareString = splittedTexts.toString()
      if (splittedTexts.length > 0 && queryParam !== compareString) {
        this.$router
          .push({
            name: 'Search',
            query: { q: splittedTexts },
          })
          .catch(() => {})
      }
      // }
      this.toggleDialog()
    },
    clear() {
      this.textarea = ''
    },
  },
}
</script>
